var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 col-xl-12" }, [
        _c("h4", { staticClass: "d-inline" }, [
          _c("i", { staticClass: "fa fa-chart-line" }),
          _vm._v(" Completions Actual Project Cost")
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "mt-3", attrs: { id: "invoice-chart" } },
      [
        _c("rotate-square2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { id: "loading-gif" }
        }),
        _c("div", { attrs: { id: "white" } }),
        _c("div", {
          staticStyle: { width: "100%", height: "640px" },
          attrs: { id: "chartdiv2" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }