<template>
  <b-card>
    <div class="row">
      <div class="col-md-12 col-xl-12">
        <h4 class="d-inline"><i class="fa fa-chart-line"></i> Completions Actual Project Cost</h4>
      </div>
    </div>

    <!-- <div class="row mb-5">
      <div class="col-md-12 col-xl-12 mt-3 mb-3">
        <b-button class="w-100 mt-1" variant="success" @click="loadInvoices()">
          Build <i class="fa fa-chart-bar"></i>
        </b-button>
      </div>
    </div> -->

    <div id="invoice-chart" class="mt-3">
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
      <div id="white"></div>
      <div id="chartdiv2" style="width: 100%; height: 640px;"></div>
    </div>
  </b-card>
</template>

<script>

import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

export default {
  name: 'Chart3',
  components: {
    Multiselect,
    RotateSquare2,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      chartData: [],
      cats: [],
      catData: [],
      categoryid: [],
      catList: [],
      loading: false,
      companyid: 0,
    }
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    async loadInvoices(id){
      this.loading = true;

      console.log(id)

      var projectids = this.projectData.map(x=>x.projectid)

      let data = JSON.stringify({
        projectids: projectids,
        companyid: id

      })
      const chartProject = await this.$axios.post('/charts/v2/3', data)
      .then(response => {
        this.createChart(response.data.result)
      })
    },
    async createChart(apiData) {
      var chart = am4core.create("chartdiv2", am4charts.XYChart);
      // Add data

      chart.colors.list = [
        am4core.color("#5c99d6"),
        am4core.color("#f07c2d"),
        am4core.color("#a2a5a5"),
        am4core.color("#72ac4e"),
        am4core.color("#947500")
      ];


      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.grid.template.location = 0;

      categoryAxis.renderer.minGridDistance = 10;

      categoryAxis.renderer.labels.template.rotation = 320;
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = "";
      valueAxis.calculateTotals = true;

      valueAxis.maxPrecision = 0;

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "label";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        //labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        return series;
      }

      let catMap = Object.keys(Object.assign({}, ...apiData));
      catMap = catMap.filter(x=>x != 'label')

      for (var i = 0; i < catMap.length; i++) {
        createSeries(catMap[i], catMap[i].replace(/_/g, ' '));
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.margin('10', '0', '0', '0')
      chart.legend.position = "bottom";

      chart.legend.useDefaultMarker = true;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");

      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = "Invoice Chart Export";
      chart.exporting.menu.items = [{
        "label": "Export <i class='fa fa-export'></i>",
        "menu": [
          {
            "label": "Image",
            "menu": [
              { "type": "png", "label": "PNG" },
              { "type": "jpg", "label": "JPG" },
              { "type": "gif", "label": "GIF" },
              { "type": "svg", "label": "SVG" },
            ]
          }, {
            "label": "Data",
            "menu": [
              { "type": "json", "label": "JSON" },
              { "type": "csv", "label": "CSV" },
              { "type": "xlsx", "label": "XLSX" }
            ]
          }, {
            "label": "Print", "type": "print"
          }
        ]
      }];

      chart.data = apiData;
      console.log(chart.data)
      this.loading = false;
    },
  },
  mounted() {
    if(this.$session.get('user') == 'pm'){
      this.$axios.get('wts/get/profile').then(response => {
        this.companyid = response.data.companyid
        this.$axios.get('wts/get/projects').then(response2 => {
          this.projectData = response2.data.result
          this.loadInvoices(response.data.companyid);
        })
      })
    }
    else{
      this.$axios.get('manager/get/profile').then(response => {
        this.companyid = response.data.companyid
        this.$axios.get('manager/get/projects').then(response2 => {
          this.projectData = response2.data.result
          this.loadInvoices(response.data.companyid);
        })
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }

  #white {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 15px;
    z-index: 9999;
  }

  #invoice-chart {
    position: relative;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      top: -50px;
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }
    .canvasjs-chart-credit {
      display: none !important;
    }

    #loading-gif {
      z-index: 9999;
      position: absolute;
      top: 200px;
      right: 50%;
    }
  }
</style>
